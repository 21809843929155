<template>
  <div>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :searchQuery="searchQuery"
      :blackoutToEdit="Boolean(blackoutToEdit)"
      @change-per-page="changePerPage"
      @search-in-table-records="searchInTableRecords"
      @refresh-blackout="reloadBlackouts"
    />

    <b-table
      :items="blackoutsData"
      :fields="fields"
      class="mb-0"
      :tbody-tr-class="rowClass"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :filter="filter.queryBlackouts"
      :busy.sync="isLoadingSomething"
      show-empty
      empty-text="No records found"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>Cargando Blackouts
        </div>
      </template>

      <template #cell(code)="data">
        <span class="text-nowrap">
          {{ data.value ? data.value : "N/A" }}
        </span>
      </template>

      <template #cell(status)="row">
        <span class="text-nowrap">
          <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
            {{ row.item.status ? "Activo" : "Desactivado" }}
          </b-badge>
        </span>
      </template>

      <!-- actions de edicion está ligado a Status -->
      <template #cell(actions)="row">
        <div>
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0 mr-1"
            text="Form"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div v-if="row.item.datesBlackout.length > 0">
              <b class="ml-1 mt-1">Fechas del Blackout</b>
                <div class="content-detail">
                  <b-dropdown-item  v-for="(date, index) in row.item.datesBlackout" :key="index" >
                      <feather-icon icon="CalendarIcon" />
                      <span class="align-middle ml-50"><b>{{date.dateStart }}  a {{date.dateEnd, }}</b></span>
                  </b-dropdown-item>
                </div>
            </div>
            <div v-else>
              <p class="m-1 text-danger">Sín fechas designadas para este blackout</p>
            </div>

          </b-dropdown>

          <b-spinner label="Loading..." variant="success" v-if="!!blackoutToEdit && blackoutToEdit == row.item.id"/>
          <span v-else class="text-center">
            <!-- @click="setBlackoutToEdit(row.item)" -->
            <b-button
              size="sm"
              variant="primary"
              :class="{ 'd-none': (!row.item.status || (!!blackoutToEdit && blackoutToEdit == row.item.id)) && !row.item.temporalDataStatus }"
              class="mr-1"
              :to="{name: 'edit-blackout', params: {id: row.item.id}}"
              v-can="'fivesclub_blackouts_show_blackouts_list_action_button_update'"
            > <feather-icon icon="Edit2Icon" size="12" />
            </b-button>

            <b-form-checkbox
              :class="{ 'd-none': row.item.status || (!row.item.status && row.item.temporalDataStatus) }"
              v-model="row.item.status"
              title="Cambiar Status"
              :value="true"
              switch
              inline
              :unchecked-value="false"
              @change="changeStatus(row.item, $event)"
              class="custom-control-success"
              v-can="'fivesclub_blackouts_show_blackouts_list_action_button_update'"
            />
          </span>

        </div>
      </template>

    </b-table>

    <PaginationTable
      v-if="blackouts.length > perPage"
      :currentPage="currentPage"
      :totalRows="blackouts.length"
      :perPage="perPage"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { formatDatesBlackout, createArrayDates } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage, toJson } from '@/helpers/helpers'
import Filters from '@/modules/fivesClub/components/catalogs/blackouts/Filters'
import PaginationTable from '@/modules/fivesClub/components/catalogs/blackouts/PaginationTable'

export default {
  mixins: [utils ],
  components: {
    Filters,
    PaginationTable
  },
  props: {
    isLoadingBlackouts:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      blackoutToEdit: null,
      isReloadingBlackouts: false,
      fields: [
        // { key: 'code', label: 'Código', class: 'text-center' },
        { key: 'name', label: 'Nombre', class: 'text-center'},
        { key: 'status', label: 'Status' },
        { key: 'temporalDataStatus', label: 'Status Revisión', formatter: value => {
          return value ? 'Esperando por Autorización': '-'
        }, class: 'text-center'},
        { key: 'actions', label: 'Acciones', class: 'text-center' },
      ],
      items: [],
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['blackouts', 'filter']),
    ...mapState('auth', ['user']),
    blackoutsData(){
      return this.blackouts.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
    isLoadingSomething(){
      return this.isLoadingBlackouts || this.isReloadingBlackouts
    }
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBlackouts','updateBlackout']),
    ...mapMutations('fivesClubCatalogs', ['setSelectedBlackout', 'setBlackouts']),
    setBlackoutToEdit(data) {
      this.$emit('set-tab-index', 2)
      data.datesBlackout = formatDatesBlackout( toJson(data.datesBlackout))
      this.setSelectedBlackout(data)
    },
    async changeStatus(item, event){
      this.blackoutToEdit = item.id
      const payload = {
        idUser: this.user.idUser,
        id: item.id,
        name: item.name,
        dates: createArrayDates(item.datesBlackout),
        status: Boolean(event),
      }
      const { status, message } = await this.updateBlackout(payload)
      this.blackoutToEdit = null
      if (status) {
        showAlertMessage('Ok: Status actualizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
        this.reloadBlackouts()
      } else {
        item.status = !item.status
        showAlertMessage('No se pudo actualizar el status', 'InfoIcon', 'Hubo un error al actualizar el status. Por favor intentelo más tarde', 'warning', 4000, 'bottom-right' )
      }
    },
    async reloadBlackouts(){
      this.isReloadingBlackouts=true
      const blackouts = await this.fetchBlackouts()
      this.setBlackouts( blackouts )
      this.isReloadingBlackouts=false
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    searchInTableRecords(string){
      this.searchQuery = string
    },
  },
};
</script>

<style scoped>
.content-detail{
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.content-detail::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.content-detail::-webkit-scrollbar{
  width: 10px;
  background-color: #F5F5F5;
}

.content-detail::-webkit-scrollbar-thumb {
  background-color: #333366;
  background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

</style>