<template>
  <b-card>
    <ListBlackouts
      :isLoadingBlackouts="isLoadingBlackouts"
    />
  </b-card>
    <!-- <b-tabs v-model="tabIndex">
      <b-tab @click="clickTab" v-if="can('fivesclub_blackouts_show_blackouts_list')">
        <template #title >
          <div> Blackouts </div>
        </template>
      </b-tab>

      <b-tab title="Crear"  @click="clickTab" v-if="can('fivesclub_blackouts_show_blackouts_add_blackout')">
        <BlackoutCreate @set-tab-index="setTabIndex"/>
      </b-tab>

      <b-tab>
        <template #title >
          <div :class="selectedBlackout ? '' : 'd-none'">
            Actualizar
          </div>
        </template>
        <BlackoutEdit @set-tab-index="setTabIndex" @get-blackouts="getBlackouts"/>
      </b-tab>

    </b-tabs> -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import ListBlackouts from '@/modules/fivesClub/components/catalogs/blackouts/ListBlackouts'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  components:{
    ListBlackouts,
  },
  data() {
    return {
      tabIndex: 0,
      isLoadingBlackouts: false
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs',['selectedBlackout']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs',['setSelectedBlackout']),
  },
};
</script>
<style scoped>
.nav-item.active.tab-active-class {
  background-color: red;
}
</style>